import { createRouter, createWebHistory } from 'vue-router';
import ApoImpressum from '@/views/ApoImpressum.vue';
import ApoDataPrivacy from '@/views/ApoDataPrivacy.vue';
import ApoMain from '@/components/ApoMain.vue';
import ApoContact from '@/forms/ApoContact.vue';
import ApoOpeningTimesOverview from '@/components/ApoOpeningTimesOverview.vue';
import ApoServices from '@/components/ApoServices.vue';
import ApoAbout from '@/views/ApoAbout..vue';

const routes = [
  {
    path: '/',
    component: ApoMain,
  },
  {
    path: '/dataprivacy',
    component: ApoDataPrivacy,
  },
  {
    path: '/imprint',
    component: ApoImpressum,
  },
  {
    path: '/about',
    component: ApoAbout,
  },
  { path: '/contact',
    component: ApoContact,
  },
  { path: '/openinghours',
    name: 'openingHours',
    component: ApoOpeningTimesOverview,
  }, 
  {
    path: '/services',
    component: ApoServices
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // `savedPosition` is used for browser's back/forward button navigation
    if (savedPosition) {
      return savedPosition; // Scroll to the saved position
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

export default router;
