<template>
                <div class=" page-heading">
                    <h1 class="text-left font-nunito text-4xl font-extrabold py-8">
                        Über uns
                    </h1>
                </div>


<p class="text-left font-nunito text-lg py-4 font-bold">Die Geschichte der Löwen Apotheke</p>

<p class="text-left font-nunito text-m  font-bold">Die Anfänge der Löwen Apotheke</p>
<p class="text-left font-nunito text-m">
Im Jahr 1794 bestanden im Herzogtum Jülich insgesamt 22 Apotheken, eine davon in der Stadt Bergheim.</p>
<p class="text-left font-nunito text-m pt-4">
Im Jahr 1804 wurde erstmals eine Liste der zugelassenen und geprüften Apotheker des Roerdepartements erstellt und veröffentlicht. Hierin finden wir für Bergheim den Apotheker Corneille Krotte (Kornelius Krott), dem am 09.Oktober 1793 die Rezeption erteilt worden war. Einer Einwohnerliste aus dem Jahr 1801/1802 ist zu entnehmen. Dass Kornelius Krott im Haus Nr. 27, also auf der Hauptstrasse in der Nähe des Aachener Tores wohnte. Das Haus des Apothekers existiert heute nicht mehr. Es ist anzunehmen, dass sich in diesem Gebäude auch die Apotheke befand. Die Apotheke besaß keinen Eigennamen, da dies in Städten mit einer Niederlassung nicht nötig war.</p>
<p class="text-left font-nunito text-m pt-4">Gegen Ende der Franzosenzeit im Rheinland konnte Kornelius Krott das Haus Nr. 71 in der Hauptstrasse erwerben. Krott verlegte auch seine Apotheke in das neue Domizil. Im Jahr 1824 scheint er die Bergheimer Apotheke aufgegeben zu haben, denn Konrad Harff übernahm die Bergheimer Apotheke in der Hauptstr. 71. Nach seinem Tod im Jahr 1828 wurde die Apotheke von seiner Witwe Gertrud und ihrem Verwalter, dem Apotheker Gartzen, weitergeführt. 1836 folgte dann der Sohn Dr. Heinrich Martin Harff. Unter Dr. Harff erhielt die Apotheke die Bezeichnung "Löwen Apotheke".</p>
<p class="text-left font-nunito text-m pt-4">Bergheimer Apotheke. Nach dessen Tod übernahm die Witwe die Konzession, während als Verwalter oder Betreiber ein E.Petzer nachweisbar ist. Seither wechselte die Apotheke mehrmals den Besitzer, wobei folgende Besitzer ermittelt werden konnten:
</p>
<ul class="text-left text-m font-nunito">
<li>- 1880 Apotheker Josef Weber</li>
<li>- 1882 Apotheker J.H.A. Schaeben</li>
<li>- 1884 Apotheker Leonhard Seil</li>
<li>- 1892 Apotheker Leopold Oster</li>
<li>- 1895 Apotheker Wilhelm Bremer</li>
<li>- 1898 Apotheker Josef Dinslage</li>
<li>- 1917 Apotheker W.Brommer</li>
<li>- 1918-1923 Verwalter Gottfried Becker</li>
<li>- 1925 Apotheker Gerhard Dinslage</li>
<li>- 1927 Apotheker Klaus Schüller</li>
<li>- 1951 Verwalter Winand Arnolds</li>
<li>- 1957-1997 Apotheker Hans Klaus Schüller</li>
   Unter der Leitung von Herrn Apotheker Hans Klaus Schüller zog die
   Apotheke von der Hauptstrasse auf die Kölner Strasse 6-10.
<li>- Seit 1997 Apotheker Michael Kisling</li>
<li>- Seit 2022 Apothekerinnen Sarah Safaa Essmat und Mariam Al-Baldawi</li>
</ul>
<div class="p-4 bg-white rounded-lg shadow-md">
    <img src="../assets/Artikel.jpg" alt="Zeitungsartikel Image" class=" justify-center rounded-lg shadow-lg w-70 h-70 object-cover">
</div>


</template>
<script setup>
</script>
<style scoped>
</style>