<template>
  <div class="container mx-auto px-4 lg:px-8">
  <a href="/"><img class="mx-auto md:mx-0 object-fill h-24 w-64" alt="Löwen Apotheke Logo" src="./assets/logo.png"></a>
  <TopBar />
  <router-view />
  <AboFooter />
</div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import AboFooter from './components/ApoFooter.vue';

export default {
  name: "App",
  components: {
    TopBar,
    AboFooter,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
