<template>
    <div  class="font-nunito text-4xl font-extrabold pt-5">Öffnungszeiten</div>
    <div  class="grid grid-cols-2 gap-2 pt-2" v-for="(value, key) in hoursData.openingHours" :key="key">
        <div class="font-nunito text-right text-l font-semibold">{{ key }}</div>
        <div class="font-nunito text-left text-l">{{ openingHoursToday(key) }}</div>
    </div>

</template>

<script setup>
import * as hoursData from '../assets/openingtimes.json'


function openingHoursToday(dayOfWeekStr) {
  let retStr = "";
  for(var i = 0; i<hoursData.openingHours[dayOfWeekStr].length; i++) {
    let openingHoursToday = hoursData.openingHours[dayOfWeekStr][i]
    if(openingHoursToday["open"] == null || openingHoursToday["close"] == null) 
    continue;
    retStr += openingHoursToday["open"] + " - " + openingHoursToday["close"]
    retStr += "\n"
  }
  if(retStr == "")
   return "Geschlossen"
  return retStr;
}
</script>