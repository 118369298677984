<template>
           <div class="text-left">
                <div class="page-heading">
                    <h1 class="font-nunito text-4xl font-extrabold py-8">
                        Datenschutzerkl&auml;rung
                    </h1>
                </div>

<div class="privacy__content">
<h2 class="text-xl py-4 font-bold">
1. Verantwortliche und Verantwortung
</h2>
<p class="text-lg py-4 font-bold">
Verantwortliche Apotheke: Löwen-Apotheke OHG
</p>
<ul class="text-lg ">
<li>Mariam Al-Baldawi</li>
<li>Sarah Essmat</li>
<li>Kölner Str. 6-10</li>
<li>50126 Bergheim</li>
</ul>
<ul class="text-lg ">
<li>Rechtsform: OHG</li>
<li>Telefon: (02271) 42345</li>
<li>Fax: (02271) 496728</li>
<li>E-Mail: <a href="mailto:info@loewen-apotheke-bergheim.de" class="link">info@loewen-apotheke-bergheim.de</a></li>
</ul>
<p class="text-lg py-4 font-bold">Zuständige datenschutzrechtliche Aufsichtsbehörde für die Apotheke:</p>
<ul class="text-lg ">
<li>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen</li>
<li>Bettina Gayk</li>
<li>Kavalleriestraße 2-4</li>
<li>40213
Düsseldorf</li>
<li>Telefon: 02 11/384 24-0</li>
<li>Fax: 02 11/384 24-999</li>
<li>E-Mail: poststelle{at}ldi.nrw{dot}de</li>
<li>Homepage: https://www.ldi.nrw.de</li>
</ul>
<h2 class="text-xl py-4 font-bold">
2. Allgemeines zur Datenverarbeitung
</h2>
<p class="text-lg">
Grundsätzlich erheben und verarbeiten wir Ihre Daten nur, soweit das für die Erbringung unserer Services und Dienste nötig ist.
</p>
<h3 class="text-lg py-4 font-bold">
2.1 Datenverarbeitung beim Besuch der Webseite
</h3>
<p class="text-lg">
Beim Besuch unserer Webseite überträgt Ihr Browser automatisch folgende Daten:
</p>
<ul class="list-disc">
<li>IP-Adresse Ihres Rechners</li>
<li>Welche Seite Sie angefordert haben</li>
<li>Unterstützte Sprachen Ihres Browsers</li>
<li>Unterstützte Übertragungsverfahren Ihres Browsers</li>
<li>Browserversion inklusive Betriebssystem</li>
<li>ggf. ob Sie einem Link gefolgt sind (Referrer)</li>
</ul>
<p class="text-lg">
In Ihrem Browser können Sie alle dieser Angaben einstellen und ggf. unterdrücken,
bis auf die IP-Adresse, die notwendig ist, um die Webseite zu Ihrem Rechner zu übertragen, und die angeforderte Seite.
</p>
<p class="text-lg">
Wir speichern diese Informationen zusammen mit:
</p>
<ul class="list-disc">
<li>Uhrzeit des Abrufs</li>
<li>Übertragene Datenmenge</li>
<li>Erfolg der Übertragung</li>
</ul>
<p class="text-lg">
in einem Logfile, das wir nutzen, um im Fehlerfall oder bei sonstigen Vorfällen die Ursache zu ermitteln. Die Speicherdauer im Logfile beträgt 14 Tage.
</p>
<p class="text-lg">
Diese Datenverarbeitung erfolgt aus berechtigtem Interesse, Art. 6 Abs. 1 lit f. DSGVO.
</p>
<h3 class="text-lg py-4 font-bold">
2.2 Datenverarbeitung bei Nutzung von Kontaktformularen, E-Mail etc.
</h3>
<p class="text-lg">
Soweit Sie uns über ein Kontaktformular oder per E-Mail oder im Rahmen von Aktionen kontaktieren, erhalten wir die Daten, die Sie uns im Rahmen dieser Anfrage übertragen. Wir nutzen sie, um Ihre Anfrage zu beantworten.
</p>
<p class="text-lg">
Die Übertragung von E-Mails erfolgt in der Regel unverschlüsselt, es sei denn, Sie nutzen – soweit angegeben – unseren PGP-Schlüssel.
</p>
<p class="text-lg">
In der Regel speichern wir diese Daten nur für die zur Beantwortung der Anfrage nötige Zeit, bei Aktionen für die Dauer des Aktionszeitraumes, sowie eine kurze Zeit darüber hinaus, um bei Rückfragen Zugriff zu haben. Es kann z.B. aus handels- oder steuerrechtlichen Gründen nötig sein, die Daten länger aufzubewahren. In dem Fall speichern wir die Daten so lange, wie es uns der Gesetzgeber vorschreibt.
</p>
<p class="text-lg">
Diese Datenverarbeitung basiert regelmäßig auf Art. 6 Abs. 1 lit. b DSGVO.
</p>
<h3 class="text-lg py-4 font-bold">
2.3. Reichweitenmessung/Google Analytics
</h3>
<p class="text-lg">
Wenn Sie eingewilligt haben, nutzen wir auf unserer Webseite Google Analytics 4. Über die konkrete Art der Einbindung, Nutzungs- und Funktionsweise dieses Dienstes klären wir im Folgenden auf.
</p>
<p class="text-lg">
Google Analytics ist ein Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, US-Konzernmutter: Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (künftig: Google). Google Analytics ermittelt als Dienstleister das Nutzungsverhalten einer Webseite und ermöglicht uns zu erkennen, ob und wie wir unsere Webseite verbessern können, welche Teile besonders relevant sind, welche weniger. Somit können wir Inhalte und Funktionen der Webseite für Sie – und uns – optimieren. Hierzu werden von Google Cookies gesetzt (Textdateien, die auf dem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch den Nutzer ermöglichen). Google Analytics wird über den Google Tag Manager eingebunden.
</p>
<p class="list-disc">
Nur mit Ihrer Einwilligung verarbeiten wir unter Verwendung von Cookies und ähnlichen Technologien die folgenden Daten:
</p>
<ul>
<li>IP-Adresse (gekürzt)</li>
<li>Browser-Typ,</li>
<li>verwendetes Betriebssystem,</li>
<li>aufgerufene Seiten,</li>
<li>Herkunftsquelle des Besuches (andere Webseite, Werbemittel)</li>
<li>Uhrzeit der Serveranfrage</li>
<li>Ihren groben Standort (basierend auf der gekürzten IP-Adresse), etwa auf Stadt/Landkreis-Ebene genau.</li>
</ul>
<p class="text-lg">
In unserem Auftrag wird Google die oben genannten Informationen benutzen, um die Nutzung der Website auszuwerten und um uns Reports über die Seitenbesuche zusammenzustellen. Dabei wird keine User-ID aktiviert und genutzt. Ein Cross-Device-Tracking über eine User-ID ist damit nicht möglich. Auch die optimierten Analysen sind deaktiviert.
</p>
<p class="text-lg">
Darüber hinaus haben wir Google untersagt, die im Rahmen der Auftragsverarbeitung gewonnenen Daten zu einer eigenen Analyse zu nutzen. Google kann unsere Daten nicht zur Analyse und Auswertung des Online-Verhaltens zur Produktverbesserung oder zum Benchmarking (Branchen-Analyse) nutzen. Ebenso haben wir dem technischen Support und den Key-Accountern den Zugriff auf unsere Daten untersagt. Weitere Informationen dazu erhalten Sie hier: <a href="https://support.google.com/analytics/answer/1011397" target="_blank" rel="noopener noreferrer nofollow">https://support.google.com/analytics/answer/1011397</a>.
</p>
<p class="text-lg">
Diese Datenverarbeitungen erfolgen auf Grundlage Ihrer Einwilligung, Art. 6 Abs. 1 lit. a DSGVO.
</p>
<p class="text-lg">
Die im Rahmen von Google Analytics verarbeiteten Daten werden für zwei Monate gespeichert und dann gelöscht. Die verwendeten Cookies werden spätestens nach 12 Monaten gelöscht.
</p>
<p class="text-lg"><b>Widerspruch/Widerruf der Einwilligung</b></p>
<p class="text-lg">
Wenn Sie dieser Datenverarbeitung widersprechen möchten oder Ihre Einwilligung widerrufen wollen, können Sie das ganz konkret auf sehr einfachem Wege tun:
</p>
<p class="text-lg">
Sie können mit Klick auf <a href="#opt-out" class="link" id="opt-out-link">diesen Link</a> widersprechen. Durch das Klicken des Links wird ein Opt-Out-Cookie im Browser hinterlegt. Ihr Wunsch, nicht an der Webseiten-/Reichweitenanalyse teilzunehmen, ist nur erkennbar, solange dieses Cookie in Ihrem Browser gespeichert ist. Ihr Widerspruch/Einwilligung gilt jeweils nur für den Browser, mit dem Sie auf den Link geklickt haben. Wenn Sie mehrere Geräte und/oder Browser haben, müssten Sie Ihren Widerspruch mit jedem Gerät einzeln zum Ausdruck bringen.
</p>
<h2 class="text-xl py-4 font-bold">
3. Unverbindliche Arzneimittelreservierung
</h2>
<p class="text-lg">
Bei der unverbindlichen Arzneimittelreservierung bitten Sie uns, für Sie Arzneimittel oder andere Apothekenprodukte
zu reservieren. Die Daten, die Sie im Rahmen der Reservierung eingeben, überträgt das System verschlüsselt zum
Server und von dort weiter zu uns. Wir haben eine sichere Übertragungstechnik, die sicherstellt, dass nur wir Kenntnis
von den übertragenen Daten erlangen.
</p>
<p class="text-lg">
Soweit wir keine Übertragung mit Telegram oder PGP-Verschlüsselung anbieten, erhalten wir Ihre Reservierung per E-Mail. E-Mails könnten theoretisch bei der Übertragung von unberechtigten Dritten mitgelesen werden.
</p>
<p class="text-lg">
Soweit wir eine Übertragung mit Telegram/PGP anbieten, ist es sichergestellt, dass Ihre Daten erst bei uns entschlüsselt werden und somit keinem Dritten zur Kenntnis gelangen können.
</p>
<p class="text-lg">
Die von Ihnen im Formular angegebenen Kontaktdaten nutzen wir für eventuelle Rückfragen im Rahmen der Reservierung. Außerdem erhalten Sie automatisch eine Bestätigung über Ihre Reservierung. Die Bestätigung enthält zum Schutz Ihrer Daten keine Informationen über den Inhalt der Reservierung. Die Datenverarbeitung basiert auf Art. 6 Abs. 1 lit. b DSGVO. Die Daten werden gelöscht, sobald der Zweck der Reservierung entfallen ist.
</p>
<h2 class="text-xl py-4 font-bold">
4. Weitergabe an Dritte
</h2>
<h3 class="text-lg py-4 font-bold">
4.1 Auftragsverarbeiter
</h3>
<p class="text-lg">
Im Rahmen unseres Angebots für Sie beauftragen wir externe Dienstleister, wie z. B. technische und Hosting-Dienstleister. Diese Dienstleister sind vertraglich zur Einhaltung der Datenschutzgesetze verpflichtet, sie dürfen insbesondere die Daten nicht für eigene Zwecke nutzen oder sie gar an Dritte weitergeben. Diese Dienstleister nutzen die Daten ausschließlich, um uns zu unterstützen, und arbeiten mit Ihren Daten nur in unserem Auftrag ausschließlich auf unsere Weisung. Wir prüfen jeden Dienstleister vorher auf die von ihm zum Datenschutz und zur Datensicherheit getroffenen Maßnahmen. So stellen wir den höchstmöglichen Schutz der personenbezogenen Daten sicher.
</p>
<h3 class="text-lg py-4 font-bold">
4.2 Sonstige Dritte
</h3>
<p class="text-lg">
Eine Datenweitergabe an sonstige Dritte ist nicht vorgesehen. Sollten wir im Einzelfall aus gesetzlichen oder vertraglichen Gründen verpflichtet sein, stellen wir sicher, dass den datenschutzrechtlichen Vorschriften genüge geleistet ist.
</p>
<h2 class="text-xl py-4 font-bold">
5. Dienste Dritter
</h2>
<h3 class="text-lg py-4 font-bold">
5.1 Einkaufen im Onlineshop der Apotheke
</h3>
<p class="text-lg">
Sofern über diese Website die Verlinkung zum Shop besteht, beachten Sie bitte die Datenschutzbestimmungen des Shops.
</p>
<h3 class="text-lg py-4 font-bold">
5.2 Kartendienst
</h3>
<p class="text-lg">
Wir verwenden den Dienst HERE Map Rendering (API) von HERE Global B.V, Kennedyplein 222-226, 5611 ZT Eindhoven, Niederlande (HERE). HERE Map Rendering ist ein Webdienst zur Darstellung von interaktiven (Land-)Karten, um geographische Informationen visuell darzustellen. Die Nutzung dieses Dienstes setzen wir ein, wenn Sie auf unsere Kontaktinformationen klicken, um Ihnen den Standort unserer Apotheke anzuzeigen, damit Sie uns schnell und unkompliziert finden und aufsuchen können.
</p>
<p class="text-lg">
Bereits beim Aufrufen der Kontakt-Seite, in der die Karte HERE eingebunden ist, werden Informationen über Ihre Nutzung unserer Website (Ihre IP-Adresse und der verwendete Browser) an Server von HERE übertragen und dort gespeichert. Ihre IP-Adresse wird nach wenigen Tagen anonymisiert.
</p>
<p class="text-lg">
Ausführliche Informationen zum Datenschutz im Zusammenhang mit der Verwendung von HERE Maps finden Sie in der Datenschutzerklärung von HERE: <a href="https://legal.here.com/de-de/privacy/policy" target="_blank" rel="noopener noreferrer nofollow">https://legal.here.com/de-de/privacy/policy</a>.
</p>
<p class="text-lg">
Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Wir wollen Ihnen einen guten Service bieten und Ihnen ermöglichen, den Weg zu uns schnell und einfach zu finden.
</p>
<h3 class="text-lg py-4 font-bold">
5.3 Instagram
</h3>
<p class="text-lg">
Soweit wir Links zu den Social Media Plattformen wie Instagram anbieten,
handelt es sich nicht um Funktionslinks wie Sharing-Buttons, sondern um reguläre Links.
Auch die Grafiken laden wir nicht von den Social-Media-Seiten herunter.
</p>
<p class="text-lg">
Auf die Datenverarbeitung durch Instagram haben wir keinen Einfluss.
</p>
<p class="text-lg">
Die jeweiligen Anbieter behaupten folgende Datenschutzmaßnahmen:
</p>
<ul class="list-disc">
<li>Instagram: <a target="_blank" rel="noopener noreferrer nofollow" class="link" href="https://help.instagram.com/155833707900388">https://help.instagram.com/155833707900388</a>
</li>
</ul>
<h2 class="text-xl py-4 font-bold">
6. Ihre Rechte
</h2>
<p class="text-lg">
Sie haben jederzeit das Recht auf Auskunft über die bei uns gespeicherten Daten sowie deren Nutzung, soweit dem nicht gesetzliche Pflichten entgegenstehen. Sie können bei fehlerhaften Daten auch jederzeit deren Korrektur verlangen.
</p>
<p class="text-lg">
Weiterhin haben Sie das Recht die Löschung Ihrer Daten zu verlangen, soweit dem keine Rechte und Pflichten unsererseits entgegenstehen. In diesem Fall können Sie verlangen, dass wir die Daten nur noch aus den Gründen nutzen dürfen, die der Löschung entgegenstehen.
</p>
<p class="text-lg">
Sofern die gesetzlichen Voraussetzungen hierfür vorliegen (Art. 20 DSGVO) haben Sie das Recht auf Erhalt der Sie betreffenden personenbezogenen Daten und das Recht auf Direktübermittlung an einen von Ihnen benannten anderen Verantwortlichen für diese Daten.
</p>
<p class="text-lg">
Ihre Rechte können Sie jederzeit durch eine formlose Nachricht an uns bzw. unseren Datenschutzbeauftragten geltend machen. Die Kontaktdaten finden Sie ganz oben in dieser Datenschutzerklärung.
</p>
<h2 class="text-lg py-4 font-bold">
7. Aufsichtsbehörde
</h2>
<p class="text-lg">
Sollten Sie Anlass zur Beschwerde haben, können Sie sich jederzeit an die für uns zuständige Datenschutzbehörde (siehe oben) wenden.
</p>
</div>
</div>
        
</template>