<template>
  <div>{{todayOpen()}}</div>
  <div v-if="openingHoursToday().length > 1" class="font-nunito"> Heute {{ openingHoursToday()}}</div>
</template>

<script setup>
import * as hoursData from '../assets/openingtimes.json'
import { ref } from 'vue';


const props = defineProps({
  isOverlayOpen: {
    type: Boolean,
    required: true,
  }
}
)

const isOverlayOpenRef = ref(props.isOverlayOpen)

function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
}

function isTimeWithinRange(
    targetTime,
    startTime,
    endTime
) {
    const targetMinutes = timeToMinutes(targetTime);
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);

    return targetMinutes >= startMinutes && targetMinutes <= endMinutes;
}

function openingHoursToday() {
  let dayOfWeekStr = new Date().toLocaleString('de', {  weekday: 'long' })
  let retStr = "";
  for(var i = 0; i<hoursData.openingHours[dayOfWeekStr].length; i++) {
    let openingHoursToday = hoursData.openingHours[dayOfWeekStr][i]
    if(openingHoursToday["open"] == null || openingHoursToday["close"] == null) 
    continue;
    retStr += openingHoursToday["open"] + " - " + openingHoursToday["close"]
    retStr += "\n"
  }
  return retStr;
}
function todayOpen() {
  let dayOfWeekStr = new Date().toLocaleString('de', {  weekday: 'long' })
  let now = new Date()
  let hours = now.getHours().toLocaleString('de')
  let mins = now.getMinutes()
  let targetTime = hours + ":" + mins
  
  let isOpen = false;
  let status = "";
  for(var i = 0; i<hoursData.openingHours[dayOfWeekStr].length; i++) {
    let openingHoursToday = hoursData.openingHours[dayOfWeekStr][i]
    if(openingHoursToday["open"] == null || openingHoursToday["close"] == null) 
        continue;
     isOpen = isTimeWithinRange(targetTime, openingHoursToday["open"], openingHoursToday["close"])
     if(isOpen) {
    if(Math.abs(timeToMinutes(openingHoursToday["close"]) - timeToMinutes(targetTime)) < 30) {
      status =  "Schließt bald"
    } else {
      status = "Geöffnet"
    }
  } else {
    if(Math.abs(timeToMinutes(openingHoursToday["open"]) - timeToMinutes(targetTime)) < 30) {
      status = "Öffnet bald"
    } else {
      status =  "Geschlossen"
    }
  }
  } 
  if(status == "" && !isOpen) {
    return "Geschlossen"
  }
  // TODO call https://get.api-feiertage.de/?states=nw
  // check opening times
  return status;
}

function toggle() {
  isOverlayOpenRef.value = !isOverlayOpenRef.value
}
</script>

<style scoped>
</style>