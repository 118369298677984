<template>
  <div class="max-w-md mx-auto p-4 pt-4 ">
    <div class=" page-heading">
                    <h1 class="text-left font-nunito text-4xl font-extrabold py-8">
                        Kontakt
                    </h1>
                </div>
    <div  v-if="showPopup"
      class="bg-white p-4 rounded-lg shadow-lg max-w-sm text-center"
      >
        <h2 class="text-lg font-semibold"></h2>
        <p class="mt-2 text-gray-600">Ihre Nachricht wurde versendet!</p>
        <RouterLink to="/" class="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 pt-3">Schließen</RouterLink>
      </div>
    <form v-else @submit.prevent="handleSubmit" action="https://api.web3forms.com/submit" method="POST">
      <div class="mb-4">
        <label for="vorname" class="text-left font-nunito block text-sm font-medium text-gray-700">Vorname</label>
        <input :value="form.access_key" type="hidden" name="access_key">
        <input
          v-model="form.vorname"
          type="text"
          id="firstname"
          class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div class="mb-4">
        <label for="nachname" class="text-left font-nunito block text-sm font-medium text-gray-700">Nachname</label>
        <input
          v-model="form.nachname"
          type="text"
          id="surname"
          class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div class="mb-4">
        <label for="telephone" class="text-left font-nunito block text-sm font-medium text-gray-700">Telefon</label>
        <input
          v-model="form.telephone"
          type="telephone"
          id="telephone"
          class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div class="mb-4">
        <label for="email" class="text-left font-nunito block text-sm font-medium text-gray-700">Email</label>
        <input
          v-model="form.email"
          type="email"
          id="email"
          class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div class="mb-4">
        <label for="message" class="text-left font-nunito block text-sm font-medium text-gray-700">Nachricht</label>
        <textarea
        id="message"
        class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        v-model="form.message"
        maxlength="4000"
        rows="5"
        cols="40"
        placeholder="Geben Sie Ihre Nachricht ein (max. 4000 Zeichen)"
        required
      ></textarea>
    </div>

      <button
        type="submit"
        class="w-full font-nunito bg-orange-600 text-white py-2 rounded-md hover:bg-orange-700"
      >
        Abschicken
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      form: {
        vorname: '',
        nachname: '',
        telephone: '',
        email: '',
        message: '',
        access_key: 'bf9044d8-3525-4197-b61a-db902a7432c3',
      },
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await fetch('https://api.web3forms.com/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });
        this.showPopup = true;
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
