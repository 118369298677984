<template>
<div class="text-left">
 <h1 class="font-nunito text-4xl font-extrabold pt-5">Impressum</h1>   
 <h2 class="text-xl py-2">Name und Anschrift</h2>
    <p class="text-m">
        Löwen-Apotheke OHG <br>
        Mariam Al-Baldawi<br>
            Sarah Essmat<br>
        Kölner Str. 6-10<br>
        50126 Bergheim<br>
    </p>
    <p class="text-m py-2">
        Rechtsform: OHG<br>
        Telefon: (02271) 42345<br>
            Fax: (02271) 496728<br>
            E-Mail: <a href="mailto:info@loewen-apotheke-bergheim.de" class="link">info@loewen-apotheke-bergheim.de</a><br>
    </p>

        <h2 class="text-xl pt-10">Registergericht</h2>
        <p class="text-lg">
            Amtsgericht Köln
        </p>

        <h2 class="text-xl">Handelsregisternummer</h2>
        <p class="text-lg">
            HRA 36129
        </p>

        <h2 class="text-xl pt-10">Zuständige Aufsichtsbehörde</h2>
        <p class="text-lg">
            Rhein-Erft-Kreis<br>
            Gesundheitsamt, 53/5<br>
            Gesundheitsaufsicht und -verwaltung<br>
            Willy-Brandt-Platz 1<br>
            50126 Bergheim<br>
                Telefon: 02271/83-15315<br>
                Fax: 02271/83-2300<br>
            <a class="link" href="mailto:info@rhein-erft-kreis.de">info@rhein-erft-kreis.de</a><br>
            <a href="https://www.rhein-erft-kreis.de/" class="link">https://www.rhein-erft-kreis.de/</a><br>
        </p>

        <h2 class="text-xl pt-10">Zuständige Kammer</h2>
        <p class="text-lg">
            Apothekerkammer Nordrhein<br>
            Poststr. 4<br>
            40213 Düsseldorf<br>
            <a href="mailto:info@aknr.de" class="link">info@aknr.de</a><br>
            <a href="https://www.aknr.de" class="link">https://www.aknr.de</a><br>
        </p>

        <h2 class="text-xl pt-10">Zuständiger Apothekerverband</h2>
        <p class="text-lg">
            Apothekerverband Nordrhein e.V.<br>
            Tersteegenstr. 12<br>
            40474 Düsseldorf<br>
            <a href="mailto:info@av-nr.de" class="link">info@av-nr.de</a><br>
            <a href="https://www.av-nr.de" class="link">https://www.av-nr.de</a><br>
        </p>


        <h2 class="text-xl pt-10">Gesetzliche Berufsbezeichnung</h2>
        <p class="text-lg">
            Approbierte Apotheker<br>
            Staat, der die Berufsbezeichnung verliehen hat:<br>
            Deutschland
        </p>

        <h2 class="text-xl pt-10">Berufsrechtliche Regelung</h2>
        <p class="text-lg">
            Apothekerkammer Nordrhein
                <br><a href="https://www.aknr.de/" class="link">https://www.aknr.de/</a>
        </p>

    <h2 class="text-xl pt-10">Bundes-Apothekenordnung</h2>
    <p class="text-lg">
        <a href="http://www.gesetze-im-internet.de" class="link">http://www.gesetze-im-internet.de</a>
    </p>
    <h2 class="text-xl pt-10">Apothekengesetz</h2>
    <p class="text-lg">
        <a href="http://www.gesetze-im-internet.de" class="link">http://www.gesetze-im-internet.de</a>
    </p>
    <h2 class="text-xl pt-10">Apothekenbetriebsordnung</h2>
    <p class="text-lg">
        <a href="http://www.gesetze-im-internet.de" class="link">http://www.gesetze-im-internet.de</a>
    </p>

        <h2 class="text-xl pt-10">Umsatzsteuer-Identifikationsnummer</h2>
        <p class="text-lg">
            DE351685984
        </p>


        <h2 class="text-xl pt-10">Streitbeilegungsverfahren nach dem Verbraucherstreitbeilegungsgesetz – VSBG:</h2>
        <p class="text-lg">
            Wir sind nicht bereit und nicht verpflichtet an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
        </p>
    </div>
</template>

<script setup>
</script>

<style scoped>
</style>